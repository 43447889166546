import splide, { customSplide } from "../utils/splide";

const banner = document.getElementById("banner");
if (banner) {
    const bannerSplide = customSplide(banner, {
        type: "fade",
        rewind: true,
        perPage: 1,
        autoplay: true,
        fixedHeight: "623px",
        speed: 2000,
        lazyLoad: "sequential",
        pagination: true,
        arrows: banner.dataset.slides > 1 ? true : false,
        breakpoints: {
            1200: {
                pagination: true,
                arrows: false,
            },
        },
    });
    bannerSplide.mount();
}

const exams = document.getElementById("exams");
if (exams) {
    const examsSplide = splide(exams, {
        perPage: 4,
        breakpoints: {
            1200: {
                perPage: 2,
                arrows: false,
                pagination: true,
            },
            600: {
                arrows:false,
                pagination: true,
                perPage: 1,
            },
        },
    });
    examsSplide.mount();
}

const covenants = document.getElementById("covenants");
if (covenants) {
    const covenantSplide = splide(covenants, {
        perPage: 4,
        breakpoints: {
            1200: {
                perPage: 3,
                arrows: false,
                pagination: true,
            },
            600: {
                perPage: 1,
            },
        },
    });
    covenantSplide.mount();
}

const specialty = document.getElementById("specialties-splide");
if (specialty) {
    const specialtySplide = splide(specialty, {
        perPage: 4,
        breakpoints: {
            1200: {
                perPage: 2,
                arrows: true,
                pagination: true,
            },
            600: {
                perPage: 1,
                arrows: true,
                pagination: true,
            },
        },
    });
    specialtySplide.mount();
}

const faqs = document.querySelectorAll('.faqs-item');

const faqsLister = (faqs) => {

    faqs.addEventListener('click', () => {

        let icon = faqs.querySelector('i');

        if (icon.classList.contains('fa-plus')) {
            icon.classList.remove('fa-plus');
            icon.classList.add('fa-times');
        } else {
            icon.classList.remove('fa-times');
            icon.classList.add('fa-plus');
        }
        let answer = faqs.querySelector('.faqs-item__answer');

        if (answer.classList.contains('show')) {
            $(answer).collapse('hide');
        } else {
            $(answer).collapse('show');
        }
    })
}

faqs.forEach(faqsLister)
